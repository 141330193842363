import moment from 'moment';
const calMinute = (from: any, to: any) => {
  if (!from && !to) return null;
  // Create Moment.js objects from the time values
  const startTimeObj = moment(from, 'YYYY-MM-DD HH:mm:ss');
  const endTimeObj = moment(to, 'YYYY-MM-DD HH:mm:ss');
  // Calculate the difference in minutes
  const diffInMinutes = endTimeObj.diff(startTimeObj, 'minutes');
  const modfive = diffInMinutes%5; 

  
  return modfive>0?diffInMinutes+1:diffInMinutes;
};

export default calMinute;
