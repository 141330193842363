import axios from 'axios';
import { useEffect, useState } from 'react';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';

const JobNumber = ({
  isView,
  dailyProductionDate,
  process,
  setRender,
}: {
  dailyProductionDate: any;
  process: any;
  isView?: any;
  setRender: any;
}) => {
  const [planningData, setJobNumbers] = useState([]);
  useEffect(() => {
    if (dailyProductionDate) {
      axios
        .get(
          `/api/dailyProductionRivetPlanning/getPlanningForDailyProduction?start_date=${dailyProductionDate}`,
        )
        .then((res) => {
          if (res.data) {
            setJobNumbers(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      process.job_id = null;
    }
  }, [dailyProductionDate]);
  return (
    <>
      <div>
        <AutocompleteMuiCustom
          id="jobs"
          label=""
          option_name="job_number"
          arrayofObj={planningData || []}
          value={process?.job_id || ''}
          onChange={(e, value) => {
            process.job_id = value;
            setRender((prev: any) => !prev);
          }}
        />
      </div>
    </>
  );
};
export default JobNumber;
