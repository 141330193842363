import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import THead from './THead';

interface IProps<T> {
  moduleData: T[];
  isView: boolean;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setIsOpenSummary: any;
  setPartIdx: any;
  handleSortChange: any;
  handleDataChange: any;
  handleSumbit: any;
}

const TController = <T,>({
  moduleData,
  isView,
  handelDeleteRows,
  isOpenPopup,
  setIsOpenPopup,
  setPartIdx,
  setIsOpenSummary,
  handleSortChange,
  handleDataChange,
  handleSumbit,
}: IProps<T>) => {
  return (
    <Box>
      <RenderTable>
        <THead />
        <TBody
          {...{
            moduleData,
            isView,
            handelDeleteRows,
            isOpenPopup,
            setIsOpenPopup,
            setPartIdx,
            setIsOpenSummary,
            handleSortChange,
            handleDataChange,
            handleSumbit,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
