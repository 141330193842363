import { useEffect, useState } from 'react';
import TController from './components/view/TController';
import axios from 'axios';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import {
  CustomButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import { useQuery } from '../../../hooks/UseQuery';
import PartTable from './components/view/PartTable';
import ModalCustom from '../../../components/common/Modal';
import useConfirm from '../../../components/common/useConfirm';
import { PersitedDatePicker } from '../../../components/common';
import { Stack } from '@mui/material';
import AutocompleteMuiCustom from '../../../components/common/AutocompleteMuiCustom';
import DaywiseSummary from './components/view/DaywiseSummary';
import { updatePlanningRecords } from './helpers/syncDailyproductionData';

const View = () => {
  const date = useQuery('date');
  const [moduleData, setModuleData] = useState<any>([]);
  const [partIdx, setPartIdx] = useState(0);
  const isView = useQuery('isView') === 'true' ? true : false;
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isOpenSummary, setIsOpenSummary] = useState(false);
  const [machineIdValue, setMachineIdValue] = useState({ _id: null });
  const [dailyProductionData, setDailyProductionData] = useState({});
  const [alertMsg, setAlertMsg] = useState('');

  const [DialogDelete, confirmDelete] = useConfirm(
    'Delete',
    'Are you sure you want to  this row permanently?',
  );

  const [machines, setMachines] = useState([]);

  useEffect(() => {
    axios
      .get('/api/machineRoutes/machine/ForAutocomplete')
      .then((res) => {
        setMachines(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const getMonthlyUpdatedData = () => {
    axios
      .get(
        `/api/dailyProductionRivetPlanning/monthly/${machineIdValue._id}?date=${date}`,
      )
      .then((res) => {
        if (res.data) {
          const productionDataByPart = res.data.productionDataByPart;
          const updatedPlans = res.data.updatedPlans;
          const modifiedModuleData = updatePlanningRecords(
            updatedPlans,
            productionDataByPart,
          );
          setDailyProductionData(productionDataByPart);
          setModuleData(modifiedModuleData);
        }
      })
      .catch((err) => {
        setModuleData([]);
        console.log(err);
      });
  };
  useEffect(() => {
    if (date && machineIdValue?._id) {
      getMonthlyUpdatedData();
    } else {
      setModuleData([]);
    }
  }, [date, machineIdValue]);

  const handleSumbit = () => {
    if (!date || !machineIdValue?._id) {
      alert('date and machine required');
      return;
    }
    setAlertMsg('SAVING...');
    return new Promise(async (resolve, reject) => {
      const modifiedModuleData = moduleData.map((item: any) => ({
        ...item,
        machine_id: machineIdValue._id,
      }));
      await axios
        .post(`/api/dailyProductionRivetPlanning`, modifiedModuleData)
        .then((res) => {
          if (res.data) {
            getMonthlyUpdatedData();
            resolve(1);
            setAlertMsg('SAVED');
          }
        })
        .catch((err) => {
          console.log(err);
          setAlertMsg('ERROR');
          resolve(0);
        });
    });
  };

  const handelDeleteRows = async (id: string, index: number) => {
    const ans = await confirmDelete();
    if (!ans) return;

    try {
      const res = await axios.delete(`/api/dailyProductionRivetPlanning/${id}`);
      if (res.data) {
        alert('Deleted Successfully');
        const updatedModuleData = [...moduleData];
        updatedModuleData.splice(index, 1);
        const modifiedModuleData = updatePlanningRecords(
          updatedModuleData,
          dailyProductionData,
        );
        setModuleData(modifiedModuleData);
      }
    } catch (err) {
      const updatedModuleData = [...moduleData];
      updatedModuleData.splice(index, 1);
      const modifiedModuleData = updatePlanningRecords(
        updatedModuleData,
        dailyProductionData,
      );
      setModuleData(modifiedModuleData);
    }
  };

  // Add a new row to the moduleData
  const addRow = async () => {
    if (!date || !machineIdValue?._id) {
      alert('date and machine required');
      return;
    }

    const month = new Date(date).getMonth() + 1;
    const year = new Date(date).getFullYear();
    const newJobNumber = `J-${month}-${year.toString().slice(-2)}-${moduleData.length ? moduleData.length + 1 : 1}`;

    const updatedModuleData = [
      ...moduleData,
      {
        month: month,
        year: year,
        job_number: newJobNumber, // Unique Job Number
        serial_number: moduleData.length + 1, // Initial serial/sort number
        machine_id: machineIdValue._id,
      },
    ];

    const modifiedModuleData = updatePlanningRecords(
      updatedModuleData,
      dailyProductionData,
    );
    setModuleData(modifiedModuleData);
  };

  const handleSortChange = (index: number, value: number) => {
    const updatedData = [...moduleData];
    updatedData[index].serial_number = value;

    // Sort based on the serial_number
    const sortedData = updatedData.sort(
      (a, b) => a.serial_number - b.serial_number,
    );

    const firstRow = sortedData.shift();
    sortedData.unshift(firstRow);
    const modifiedModuleData = updatePlanningRecords(
      sortedData,
      dailyProductionData,
    );
    setModuleData(modifiedModuleData);
  };

  const handleDataChange = (index: number, field: string, value: any) => {
    setAlertMsg('');
    const updatedData = [...moduleData];
    updatedData[index] = { ...updatedData[index], [field]: value };

    const modifiedModuleData = updatePlanningRecords(
      updatedData,
      dailyProductionData,
    );
    setModuleData(modifiedModuleData);
  };

  return (
    <>
      <Box>
        <ChildHeader text={'DAILY PRODUCTION RIVET DASHBOARD PLANNING'}>
          {!isView && (
            <>
              <h3>{alertMsg}</h3>
              <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                  marginLeft: '0.4rem',
                }}
                onClick={addRow}
              >
                ADD ROWS
              </CustomButton>
              <SubmitButton
                label="UPDATE"
                onClick={async () => await handleSumbit()}
              />
            </>
          )}
        </ChildHeader>
      </Box>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'start',
          alignItems: 'center',
          gap: 2,
          m: 1,
        }}
      >
        <PersitedDatePicker label="Search By Year" views={['year', 'month']} />
        <Box width={200}>
          <AutocompleteMuiCustom
            id="Machine"
            label=""
            option_name="machine_no"
            arrayofObj={machines || []}
            value={machineIdValue || ''}
            onChange={(e, value) => {
              setMachineIdValue(value);
            }}
          />
        </Box>
      </Stack>

      <TController
        {...{
          moduleData,
          isView,
          handelDeleteRows,
          isOpenPopup,
          setIsOpenPopup,
          setPartIdx,
          setIsOpenSummary,
          handleSortChange,
          handleDataChange,
          handleSumbit,
        }}
      />
      <ModalCustom
        title="PART LIST"
        openModal={isOpenPopup}
        closeModal={async () => {
          setIsOpenPopup(false);
          await handleSumbit();
        }}
      >
        <PartTable moduleData={moduleData[partIdx]} />
      </ModalCustom>
      <ModalCustom
        title="DAYWISE SUMMARY"
        openModal={isOpenSummary}
        closeModal={() => {
          setIsOpenSummary(false);
        }}
      >
        <DaywiseSummary records={moduleData[partIdx]?.records} />
      </ModalCustom>
      <DialogDelete />
    </>
  );
};

export default View;
