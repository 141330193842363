import moment from 'moment';
import { useState } from 'react';
import { DeleteIconButton } from '../../../../components/common/button/CustomIconButton';
import { TimePicker } from '@mui/x-date-pickers';
import CustomSelect from '../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import { calMinute, convToTime } from '../../../../utils/helpers';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  downTimes: any;
}

function calculateTotalPlannedDowntime(details: any) {
  return details
    .filter((item: any) => item.task !== 'Working' && item.task)
    .reduce((total: any, item: any) => {
      const differenceInMinutes = calMinute(item.start_time, item.end_time);
      return total + differenceInMinutes || 0;
    }, 0);
}

const TBody = ({ downTimes, moduleData, isView, handelDeleteRows }: IProps) => {
  const handleTimeChange = (item: any, key: string, date: any) => {
    if (date) {
      const updatedTime = moment(date).set({ second: 0, millisecond: 0 });
      item[key] = updatedTime.toISOString(); // Use the directly returned moment object from TimePicker
      setRender((prev) => !prev);
    }
  };
  
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData.details &&
        moduleData.details.map((item: any, index: number) => (
          <tr>
            <td>{index + 1}</td>
            <td style={{ minWidth: '150px' }}>
              {isView ? (
                convToTime(item.start_time)
              ) : (
                <TimePicker
                  value={item.start_time ? moment(item.start_time) : null}
                  onChange={(date) => handleTimeChange(item, 'start_time', date)}
                  // value={(item.start_time && moment(item.start_time)) || null}
                  // onChange={(date) => {
                  //   const updatedStartTime = moment().set({
                  //     hour: date.hour(),
                  //     minute: date.minute(),
                  //     second: date.second(),
                  //   });

                  //   item.start_time = updatedStartTime.toISOString();
                  //   setRender((prev) => !prev);
                  // }}
                />
              )}
            </td>
            <td style={{ minWidth: '150px' }}>
              {isView ? (
                convToTime(item.end_time)
              ) : (
                <TimePicker
                  value={item.end_time ? moment(item.end_time) : null}
                  onChange={(date) => handleTimeChange(item, 'end_time', date)}
                  // value={(item.end_time && moment(item.end_time)) || null}
                  // onChange={(date) => {
                  //   const updatedStartTime = moment().set({
                  //     hour: date.hour(),
                  //     minute: date.minute(),
                  //     second: date.second(),
                  //   });

                  //   item.end_time = updatedStartTime.toISOString();
                  //   setRender((prev) => !prev);
                  // }}
                />
              )}
            </td>
            <td style={{ minWidth: '150px' }}>
              <CustomSelect
                name="task"
                key="task"
                value={item?.task}
                onChange={(e) => {
                  item.task = e.target.value;
                  setRender((prev) => !prev);
                }}
              >
                {downTimes.map((downtime: any) => (
                  <MenuItem value={downtime}>{downtime}</MenuItem>
                ))}
              </CustomSelect>
            </td>
            {index === 0 && (
              <td
                style={{ minWidth: '150px' }}
                rowSpan={moduleData.details.length}
              >
                {moduleData.shift}
              </td>
            )}
            <td style={{ minWidth: '150px' }}>
              {calMinute(item.start_time, item.end_time)}
            </td>
            {!isView && (
              <td style={{ minWidth: '100px', textAlign: 'center' }}>
                <DeleteIconButton
                  tooltipTitle={'delete'}
                  onClick={() => handelDeleteRows(index)}
                />
              </td>
            )}
          </tr>
        ))}
      <tr>
        <td colSpan={5}>Total Planned Downtime(mins)</td>
        <td colSpan={2}>
          {moduleData.details &&
            calculateTotalPlannedDowntime(moduleData.details)}
        </td>
      </tr>
    </tbody>
  );
};

export default TBody; 
