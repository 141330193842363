import { AiOutlinePartition } from 'react-icons/ai';
import { FaDatabase, FaUserCog } from 'react-icons/fa';
import { GiMachineGun } from 'react-icons/gi';
import { GoTools } from 'react-icons/go';
import { MdDashboard, MdOutlineAccessTime } from 'react-icons/md';
import { TbBuildingWarehouse } from 'react-icons/tb';
import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { BsList } from 'react-icons/bs';
export type tilesType = {
  sNo: number;
  link: string;
  isLink: boolean;
  isIcon: boolean;
  clauseNo?: string | number;
  p: string;
  span: string;
  icon?: any;
  style?: {};
}[];
const tiles = [
  {
    sNo: 1,
    link: '/admin/masterlistofmachine?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <AiOutlinePartition size="5rem" color="#a12222" />,
    p: 'Master List Of',
    span: 'Machine',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/admin/manMachineMatrixList?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <FaUserCog size="5rem" color="#135089" />,
    p: 'Man Machine',
    span: 'Matrix',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/masterListOfCriticalSparePart?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <TbBuildingWarehouse size="5rem" color="#a12222" />,
    p: 'Master List Of',
    span: 'Critical Spare Parts',
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/dailyProductionTiles',
    isLink: true,
    clauseNo: 4,
    icon: <TbBuildingWarehouse size="5rem" color="#a12222" />,
    p: 'Daily Production',
    span: 'Report',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/defectList',
    isLink: true,
    clauseNo: 4,
    icon: <BsList size="5rem" color="#135089" />,
    p: 'MASTER LIST OF',
    span: 'DOWNTIME REASON',
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/createEditRevitDailyProductionPlanning',
    isLink: true,
    clauseNo: 4,
    icon: <MdDashboard size="5rem" color="#a12222" />,
    p: 'Planning',
    span: 'Dashboard',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/masterlistofToolList',
    isLink: true,
    clauseNo: 4,
    icon: <GoTools size="5rem" color="#343a40" />,
    p: 'Master List Of',
    span: 'Tool',
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/obseleteMasterlistofToolList',
    isLink: true,
    clauseNo: 4,
    icon: <GoTools size="5rem" color="#343a40" />,
    p: 'Master List Of',
    span: 'Obselete Tool',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/itemMasterVsMachineAndTool',
    isLink: true,
    icon: <GiMachineGun size="5rem" color="#343a40" />,
    p: 'ITEM MASTER VS',
    span: 'MACHINE AND TOOL',
    isIcon: true,
  },
  {
    sNo: 6,
    link: '/common/viewItemMasterList',
    isLink: true,
    icon: <MdOutlineAccessTime size="5rem" color="#343a40" />,
    p: 'PART STROKE',
    span: 'RATE',
    isIcon: true,
  },
  {
    sNo: 6,
    link: '/common/shiftCreationTiles',
    isLink: true,
    icon: <FaDatabase size="5rem" color="#343a40" />,
    p: 'SHIFT',
    span: 'CREATION',
    isIcon: true,
  },
];
const Clause = () => {
  return (
    <>
      <ChildHeader text="Production & Maintenance Department" />
      <RenderTiles tilesObj={tiles} height="100vh" justify="start" />
    </>
  );
};

export default Clause;
