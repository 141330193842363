import { Production } from '../pages/ProductionPage';
import {
  DailyProduction,
  CreateDailyProduction,
} from '../pages/ProductionPage/dailyProductionRotor';
import {
  DailyProductionStrokes,
  CreateDailyProductionStrokes,
} from '../pages/ProductionPage/dailyProductionStrokes';
import {
  DailyProductionRedBin,
  CreateDailyProductionRedBin,
} from '../pages/ProductionPage/dailyProductionRedBin';
import {
  HourlyProductionReportList,
  CreateHourlyProductionReport,
} from '../pages/ProductionPage/HourlyProductionReport';

import {
  DailyRejectionRotorList,
  CreateDailyRejectionRotor,
} from '../pages/ProductionPage/dailyProductionRotorDailyRejection';
import PDCSection from '../pages/ProductionPage/PDCSection';
import PressShop from '../pages/ProductionPage/PressShop';
import RotorSection from '../pages/ProductionPage/RotorSection';
import { Profile } from '../pages/profile';
import CNCSection from '../pages/ProductionPage/CNCSection';
import {
  CNCRedBinList,
  CreateViewCNCRedBin,
} from '../pages/ProductionPage/redBinCNC';
import {
  PressShopRedBinList,
  CreateViewPressShopRedBin,
} from '../pages/ProductionPage/redBinPressShop';
import {
  RotorSectionRedBinList,
  CreateViewRotorSectionRedBin,
} from '../pages/ProductionPage/redBinRotorSection';
import {
  DailyHourlyCNC,
  CreateDailyHourlyCNC,
} from '../pages/ProductionPage/dailyProdCNCMachining';
import { ClauseUploadList } from '../pages/clauseUpload';
import { PdcHome, PressShopHome, RotorHome } from '../pages/clause3';
// clause 8 modules
import {
  AbnormalityLogBook,
  CreateAbnormalityLogBook,
} from '../pages/abnormalityLogBook';
import {
  DownloadTestPaperHome,
  EffectivenessTestHome,
  SkillEvaluationHome,
  TNITiles,
  TrainingTniHome,
} from '../pages/clause5page';
import {
  CreateForMSetup,
  CreateForMSummary,
  SummaryTableList,
  CreateHoldCard,
  TrainingRecord,
} from '../pages/4m';
import MaintenanceTiles from '../pages/clause10Page/maintenance/MaintenanceLogTiles';
import {
  PreventiveMaintenaceCreate,
  PreventiveMaintenaceList,
} from '../pages/clause10Page/maintenance/preventiveMaintenancePlan';
import {
  PreventiveMaintenaceCheckListCreate,
  PreventiveMaintenaceCheckList,
} from '../pages/clause10Page/maintenance/preventiveMaintenanceStatus';
import {
  ChecklistList,
  InstrumentTiles,
  PlanCreate,
  PlanList,
} from '../pages/clause10Page/instrumentPlan';
import {
  InstrumentMasterList,
  InstrumentHistoryCard,
} from '../pages/masterListOfInstrument/index';
// import { InstrumentMasterList } from '../pages/masterListOfInstrument';
import {
  AnnualPmPlanRotorCastingTiles,
  AnnualPmPlanRotorCastingList,
  AnnualPmPlanRotorCastingCreate,
  AnnualPmPlanRotorCastingChecklistList,
} from '../pages/clause10Page/AnnualPmPlanRotorCasting';
import { AnnualPmPlanRostorCastingMasterList } from '../pages/clause10Page/AnnualPmPlanRotorCasting/masterlistofInstrument';
import {
  AnnualPmPlanUtilityList,
  AnnualPmPlanUtilityCreate,
  AnnualPmPlanUtilityChecklistList,
  AnnualPmPlanChecklistCreate,
  AnnualPmPlanUtilityProductWiseTiles,
} from '../pages/clause10Page/annualPmPlanUtility/productWise';
import {
  SpcPlanTiles,
  SpcPlanList,
  SpcPlanCreate,
  SpcPlanChecklistList,
} from '../pages/spcPlan';
// import { AnnualPmPlanRostorCastingMasterList } from '../pages/clause10Page/AnnualPmPlanRotorCasting/masterlistofInstrument';
import { BreakDownFinalList } from '../pages/clause10Page/maintenanceLog/breakDownLogBook'; //pdirIAI/pdirFinalList';
import MaintenanceLogTiles from '../pages/clause10Page/maintenanceLog/MaintenanceLogTiles';
import {
  MtbfList,
  MtbfCreate,
} from '../pages/clause10Page/maintenanceLog/MTBF';
import {
  MttrList,
  MttrCreate,
} from '../pages/clause10Page/maintenanceLog/MTTR';
import { CriticalSparePartsList } from '../pages/masterListOfCriticalSpareParts';
import {
  AnnualProductMachineWiseAuditTiles,
  MasterListofAppearancePlan,
} from '../pages/clause10Page';
import QuestionPaperList from '../pages/questionPaper/List';
import CreateEditQuestionPaper from '../pages/questionPaper/Create';
import QuickViewQuestionPaper from '../pages/questionPaper/View';
import TNITopicList from '../pages/clause5page/training(TNI)/TNI/createTNITopics/List';
import CreateEditTopicPaper from '../pages/clause5page/training(TNI)/TNI/createTNITopics/Create';
import QuickViewTopicPaper from '../pages/clause5page/training(TNI)/TNI/createTNITopics/View';
import SkillEvaluationList from '../pages/clause5page/skillEvaluation/List';
import AddEditViewSkillEvaluation from '../pages/clause5page/skillEvaluation/Create';
import SkillMatrixList from '../pages/clause5page/skillMatrix/List';
import AddEditViewSkillMatrix from '../pages/clause5page/skillMatrix/Create';
import {
  TniReportList,
  TniReportCreate,
} from '../pages/clause5page/training(TNI)/TNI/TniReport';
import AnnualTrainingList from '../pages/clause5page/annualTrainingPlan/List';
import CreateEditAnnalTraining from '../pages/clause5page/annualTrainingPlan/Create';
import { AnnualTrainingStatusList } from '../pages/clause5page/annualTrainingStatus';
import QualityDepartmentUpload from '../pages/clause5page/qualityDepartmentUpload/QualityDepartmentUpload';
import List from '../pages/employee/List';
import FacultyList from '../pages/msterListOfFaculty/List';
import MasterlistOfDepartment from '../pages/department/List';

// spc starting for iai in qlty dept
import PartListForSPC from '../pages/spc/PartListForSPC';
import SpcTiles from '../pages/spc/Tiles';
import SpcList from '../pages/spc/List';
import CreateEditSpc from '../pages/spc/CreateEdit';
import { CreateEditMsa, MSAList } from '../pages/MSA';

import CreateNcReport from '../pages/ncReport/Create';
import NcReportList from '../pages/ncReport/TableList';
import {
  AbnormalityHandling,
  CustomerComplaintList,
  InHouseComplaintList,
  ListOfDefects,
  SupplierComplaintList,
} from '../pages/abnormalityHandling';
import {
  MsaChecklistList,
  MsaPlanCreate,
  MsaPlanList,
  TilesMsa,
} from '../pages/msaPlan';
import {
  CallibrationPlanList,
  CallibrationPlanCreate,
  CallibrationChecklistList,
  TilesCallibration,
} from '../pages/calibrationPlan';
import {
  SupplierCompliantList,
  SupplierCompliantCreate,
} from '../pages/supplierComplaint';
import {
  CustomerCompliantList,
  CustomerCompliantCreate,
} from '../pages/customerComplaint';
import {
  CreateRevitDailyProduction,
  CreateRevitDailyProductionPlanning,
  DailyProductionTile,
  DefectList,
  ProductionAndMaintenanceTiles,
} from '../pages/productionAndMaintenance';
import { MasterListLocationList } from '../pages/masterListLocation';
import { MasterListTool, ObseleteToolList } from '../pages/masterlisofTools';
import { ItemMasterVsMachineAndTool } from '../pages/itemMasterVsMachinAndTool';
import {
  AnnualPmPlanMachineWiseChecklistCreate,
  AnnualPmPlanMachineWiseChecklistList,
  AnnualPmPlanMachineWiseCreate,
  AnnualPmPlanMachineWiseList,
  AnnualPmPlanMachineWiseTiles,
} from '../pages/clause10Page/annualPmPlanUtility/machineWise';
import MasterListOfApprovedExtenalProviderList from '../pages/masterListOfApprovedExternalProvider/MasterListApprovedExternalProviderList';
import { ObsoleteInstrumentMasterList } from '../pages/masterListOfInstrumentObsolete';
import InHouseHome from '../pages/abnormalityHandling/inhouse/InHouseHome';
import { MushinHelpDesk } from '../pages/Mushin_Help_Desk';
import { HireCandidatePortalList } from '../pages/hireCandidatePortal';
import { MasterListDesignation } from '../pages/clause5page/masterListDesignation';
import SubDepartmentList from '../pages/subDepartment/SubDepartmentList';
import MushinAquaMastersTiles from '../pages/mushinAquaMasters/MushinAquaMastersTiles';
import { MasterListEmployeeTypeList } from '../pages/masterListEmployeeType';
import { MasterListQualificationList } from '../pages/masterListQualification';
import { MasterListSkillLevelList } from '../pages/masterListSkillLevel';
import ItemMasterList from '../pages/partStrokeRate/ItemMasterList';
import PartStrokeList from '../pages/partStrokeRate/PartStrokeList';
import ListOfScheduleDowntime from '../pages/listOfScheduleDowntime/ListOfScheduleDowntime';
import ShiftCreationTiles from '../pages/productionAndMaintenance/shiftCreationTiles';
import CreateShiftSchedulingDetails from '../pages/shiftSecheduling/shiftSchedulingDetails/CreateShiftSchedulingDetails';
import ShiftSchedulingList from '../pages/shiftSecheduling/ShiftSchedulingList';

interface routes {
  path: string;
  element: JSX.Element;
  errorElement?: string;
}

interface routes {
  path: string;
  element: JSX.Element;
  errorElement?: string;
}

export const other: routes[] = [
  {
    path: 'profile',
    element: <Profile />,
  },
  {
    path: 'pdc',
    element: <PDCSection />,
  },
  {
    path: 'rotorSection',
    element: <RotorSection />,
  },
  {
    path: 'pressShop',
    element: <PressShop />,
  },
  {
    path: 'cnc',
    element: <CNCSection />,
  },
];

export const productions = [
  {
    path: 'production',
    element: <Production />,
  },
  // {
  //   path: 'dailyProductionList',
  //   element: <DailyProduction />,
  // },

  // --------------------------- rotor section -------------------------------------------------------------------------
  // daily prod rotor report
  {
    path: 'rotorSection/dailyProductionList',
    element: <DailyProduction />,
  },
  {
    path: 'createEditDailyProduction/:id?',
    element: <CreateDailyProduction />,
  },

  // daily rotor rejection report
  {
    path: 'rotorSection/dailyProductionRotorDailyRejectionList',
    element: <DailyRejectionRotorList />,
  },
  {
    path: 'createEditDailyProductionRotorDailyRejection/:id?',
    element: <CreateDailyRejectionRotor />,
  },
  // daily production redbin list (doubt it is to be deleted or not)
  // {
  //   path: 'rotorSection/dailyProductionRedBinList',
  //   element: <DailyRejectionRotorList />,
  // },
  // {
  //   path: 'createEditDailyProductionRotorDailyRejection/:id?',
  //   element: <CreateDailyRejectionRotor />,
  // },
  // redbin prashant
  {
    path: 'rotorSection/redbinRotorSection',
    element: <RotorSectionRedBinList />,
  },
  {
    path: 'rotorSection/createEditRedbinRotorSection/:id?',
    element: <CreateViewRotorSectionRedBin />,
  },

  // ----------------------------------end of rotor section -----------------------------------------------------------------------

  // -------------------------------------------pdc section -------------------------------------------------------------------------
  // hourly report
  {
    path: 'pdc/rotorHourlyProductionList',
    element: <HourlyProductionReportList />,
  },
  {
    path: 'createHourlyProductionReports/:id?',
    element: <CreateHourlyProductionReport />,
  },

  // prodRed bin pdc section
  {
    path: 'pdc/dailyProductionRedBinList',
    element: <DailyProductionRedBin />,
  },
  {
    path: 'createEditDailyProductionRedBin/:id?',
    element: <CreateDailyProductionRedBin />,
  },

  // ---------------------------------------------- press shop section ----------------------------------------------
  // dailyprod strokes
  {
    path: 'pressShop/dailyProductionStrokesList',
    element: <DailyProductionStrokes />,
  },
  {
    path: 'createEditDailyProductionStrokes/:id?',
    element: <CreateDailyProductionStrokes />,
  },

  {
    path: 'pressShop/redbinPressShop',
    element: <PressShopRedBinList />,
  },
  {
    path: 'pressShop/createEditRedbinPressShop/:id?',
    element: <CreateViewPressShopRedBin />,
  },

  // redbin

  // ---------------------------------------------- end of press shop section ----------------------------------------------

  // ------------------------------------------------- cnc secction --------------------------------------------------------

  // hourly prod cnc
  {
    path: 'cnc/dailyHourlyProdCNCList',
    element: <DailyHourlyCNC />,
  },
  {
    path: 'cnc/createEditDailyHourlyProdCNC/:id?',
    element: <CreateDailyHourlyCNC />,
  },
  // redbin cnc
  {
    path: 'cnc/redbinCNC',
    element: <CNCRedBinList />,
  },
  {
    path: 'cnc/createEditRedbinCNC/:id?',
    element: <CreateViewCNCRedBin />,
  },
];
const clause5Eduction = [
  {
    path: 'masterlistofdepartment',
    element: <MasterlistOfDepartment />,
  },
  {
    path: 'masterlistofsubdepartment',
    element: <SubDepartmentList />,
  },
  {
    path: 'employee_list',
    element: <List />,
  },
  {
    path: 'masterlistoffaculty',
    element: <FacultyList />,
  },
  {
    path: 'effectivenessTestPaperHome',
    element: <EffectivenessTestHome />,
  },
  {
    path: 'effectivenessTestPaperHome',
    element: <EffectivenessTestHome />,
  },
  {
    path: 'downloadTestPaper',
    element: <DownloadTestPaperHome />,
  },
  {
    path: 'createTestPaper',
    element: <QuestionPaperList />,
  },
  {
    path: 'createEditQuestionPaper/:id?',
    element: <CreateEditQuestionPaper />,
  },
  {
    path: 'quick_view_questionpaper/:id',
    element: <QuickViewQuestionPaper />,
  },
  {
    path: 'trainingTniHome',
    element: <TrainingTniHome />,
  },
  {
    path: 'tniTiles',
    element: <TNITiles />,
  },
  {
    path: 'topicPaper/list',
    element: <TNITopicList />,
  },
  {
    path: 'createEditTopicPaper/:id?',
    element: <CreateEditTopicPaper />,
  },
  {
    path: 'quick_view_topic_paper/:id',
    element: <QuickViewTopicPaper />,
  },
  {
    path: 'skillEvaluationHome',
    element: <SkillEvaluationHome />,
  },
  {
    path: 'SkillEvaluationList',
    element: <SkillEvaluationList />,
  },
  {
    path: 'createEditViewSkillEvaluation/:id',
    element: <AddEditViewSkillEvaluation />,
  },
  {
    path: 'skillMatrixList',
    element: <SkillMatrixList />,
  },
  {
    path: 'createEditViewSkillMatrix/:id',
    element: <AddEditViewSkillMatrix />,
  },
  {
    path: 'tniList/data',
    element: <TniReportList />,
  },
  {
    path: 'createEditTNI/report/:id',
    element: <TniReportCreate />,
  },
  {
    path: 'annualTrainingList',
    element: <AnnualTrainingList />,
  },
  {
    path: 'create_edit_annualTraning/list/:id?',
    element: <CreateEditAnnalTraining />,
  },
  {
    path: 'trainginStatusList',
    element: <AnnualTrainingStatusList />,
  },
  {
    path: 'qualityDepartmentUpload',
    element: <QualityDepartmentUpload />,
  },
];

const clause3ForM = [
  {
    path: '4mPdc',
    element: <PdcHome />,
  },
  {
    path: '4mPressShop',
    element: <PressShopHome />,
  },
  {
    path: '4mRotor',
    element: <RotorHome />,
  },
];

const clause8Modules = [
  // abnormality Log book
  {
    path: 'abnormalityLogBookList',
    element: <AbnormalityLogBook />,
  },
  {
    path: 'createAbnormalityLogBook/:id?',
    element: <CreateAbnormalityLogBook />,
  },
];

const forMSummary = [
  {
    path: 'create4mSummary',
    element: <CreateForMSummary />,
  },
  {
    path: '4mSummaryList',
    element: <SummaryTableList />,
  },
  {
    path: 'create4mSetup/:partId/:rowId/:setupId?',
    element: <CreateForMSetup />,
  },
  {
    path: 'createHoldCard/:id?',
    element: <CreateHoldCard />,
  },
  {
    path: 'trainingRecord/:id?',
    element: <TrainingRecord />,
  },
];

const spc = [
  // spc for iai
  {
    path: 'spc/partlist',
    element: <PartListForSPC />,
  },
  {
    path: 'spcTiles/:partId',
    element: <SpcTiles />,
  },
  {
    path: 'newSpc/:partId',
    element: <SpcList />,
  },
  {
    path: 'createEditSpc/:partId/:id?',
    element: <CreateEditSpc />,
  },
];
const MSA = [
  {
    path: 'MSAList',
    element: <MSAList />,
  },
  {
    path: 'CreateEditMsa/:partId/:id?',
    element: <CreateEditMsa />,
  },
];

const ncReport = [
  {
    path: 'abnormalityHandling',
    element: <AbnormalityHandling />,
  },
  {
    path: 'createncReport',
    element: <CreateNcReport />,
  },
  {
    path: 'ncReportList',
    element: <NcReportList />,
  },
  {
    path: 'inHouseHome',
    element: <InHouseHome />,
  },
  {
    path: 'inHouseComplaintRegisterList',
    element: <InHouseComplaintList />,
  },
];

export const IndexCommon: routes[] = [
  {
    path: 'mushinAquaMastersTiles',
    element: <MushinAquaMastersTiles />,
  },
  {
    path: 'clauseUploadList',
    element: <ClauseUploadList />,
  },
  {
    path: 'maintenance_breakdown',
    element: <MaintenanceTiles />,
  },
  {
    path: 'maintenance_breakdown/preventive_maintenance_list',
    element: <PreventiveMaintenaceList />,
  },
  {
    path: 'maintenance_breakdown/preventive_maintenance_create/:id',
    element: <PreventiveMaintenaceCreate />,
  },
  {
    path: 'maintenance_breakdown/preventive_maintenance_check_list',
    element: <PreventiveMaintenaceCheckList />,
  },
  {
    path: 'maintenanceForCheckedMachineForm/:id/:checklistType',
    element: <PreventiveMaintenaceCheckListCreate />,
  },
  {
    path: 'instrumentTiles',
    element: <InstrumentTiles />,
  },
  {
    path: 'instrumentplanlist',
    element: <PlanList />,
  },
  {
    path: 'instrumentplancreate/:id',
    element: <PlanCreate />,
  },
  {
    path: 'instrumentMasterlist',
    element: <InstrumentMasterList />,
  },
  {
    path: 'obsoleteInstrumentMasterlist',
    element: <ObsoleteInstrumentMasterList />,
  },
  {
    path: 'instrumentHistoryCard/:id',
    element: <InstrumentHistoryCard />,
  },
  {
    path: 'checklistList',
    element: <ChecklistList />,
  },
  // Annual PM Plan
  {
    path: 'annualPmPlanRotorCastingTiles',
    element: <AnnualPmPlanRotorCastingTiles />,
  },
  {
    path: 'annualPmPlanRotorCastinglist',
    element: <AnnualPmPlanRotorCastingList />,
  },
  {
    path: 'annualPmPlanRotorCastingCreate/:id',
    element: <AnnualPmPlanRotorCastingCreate />,
  },
  {
    path: 'annualPmPlanRotorCastingMasterlist',
    element: <AnnualPmPlanRostorCastingMasterList />,
  },
  {
    path: 'annualPmPlanRotorCastingChecklist',
    element: <AnnualPmPlanRotorCastingChecklistList />,
  },
  // Annual PM Plan Utility
  {
    path: 'annualPmPlanUtilityTiles',
    element: <AnnualProductMachineWiseAuditTiles />,
  },
  // product wise audit
  {
    path: 'productWiseAudit',
    element: <AnnualPmPlanUtilityProductWiseTiles />,
  },
  {
    path: 'annualPmPlanUtilitylist',
    element: <AnnualPmPlanUtilityList />,
  },
  {
    path: 'annualPmPlanUtilityCreate/:id',
    element: <AnnualPmPlanUtilityCreate />,
  },
  {
    path: 'annualPmPlanUtilityChecklist',
    element: <AnnualPmPlanUtilityChecklistList />,
  },
  {
    path: 'annualPmPlanChecklistCreate/:moduleId?',
    element: <AnnualPmPlanChecklistCreate />,
  },
  //machine wise audit
  {
    path: 'machineWiseAudit',
    element: <AnnualPmPlanMachineWiseTiles />,
  },
  {
    path: 'annualPmPlanUtilityMachinelist',
    element: <AnnualPmPlanMachineWiseList />,
  },
  {
    path: 'annualPmPlanMachineWiseCreate/:id',
    element: <AnnualPmPlanMachineWiseCreate />,
  },
  {
    path: 'annualPmPlanUtilityMachineChecklist',
    element: <AnnualPmPlanMachineWiseChecklistList />,
  },
  {
    path: 'annualPmPlanChecklistMachineWiseCreate/:moduleId?',
    element: <AnnualPmPlanMachineWiseChecklistCreate />,
  },
  // Annual SPC Plan
  {
    path: 'spcPlanTiles',
    element: <SpcPlanTiles />,
  },
  {
    path: 'spcPlanlist',
    element: <SpcPlanList />,
  },
  {
    path: 'spcPlanCreate/:id',
    element: <SpcPlanCreate />,
  },
  {
    path: 'spcPlanChecklist',
    element: <SpcPlanChecklistList />,
  },
  {
    path: 'maintenanceLog',
    element: <MaintenanceLogTiles />,
  },
  {
    path: 'breakDownLogBook',
    element: <BreakDownFinalList />,
  },
  {
    path: 'mtbf',
    element: <MtbfList />,
  },
  {
    path: 'mtbfCreate/:id',
    element: <MtbfCreate />,
  },
  {
    path: 'mttr',
    element: <MttrList />,
  },
  {
    path: 'mttrCreate/:id',
    element: <MttrCreate />,
  },
  {
    path: 'masterListOfCriticalSparePart',
    element: <CriticalSparePartsList />,
  },
  {
    path: 'appearanceplanMasterlist',
    element: <MasterListofAppearancePlan />,
  },

  // {
  //   path: 'checklist_actions_list?documentType=preventive',
  //   element: <MttrList />,
  // },
  // {
  //   path: 'mttrCreate/:id',
  //   element: <MttrCreate />,
  // },
  // MSA PLAN
  {
    path: 'tilesMsaplan',
    element: <TilesMsa />,
  },
  {
    path: 'msaplanlist',
    element: <MsaPlanList />,
  },
  {
    path: 'msaplancreate/:id',
    element: <MsaPlanCreate />,
  },
  {
    path: 'msachecklistList',
    element: <MsaChecklistList />,
  },

  // CALLIBRATION PLAN
  {
    path: 'tilesCallibrationplan',
    element: <TilesCallibration />,
  },
  {
    path: 'callibrationplanlist',
    element: <CallibrationPlanList />,
  },
  {
    path: 'callibrationplancreate/:id',
    element: <CallibrationPlanCreate />,
  },
  {
    path: 'callibrationchecklistList',
    element: <CallibrationChecklistList />,
  },
  {
    path: 'supplierCompliant',
    element: <SupplierCompliantList />,
  },
  {
    path: 'supplierCompliantCreate',
    element: <SupplierCompliantCreate />,
  },
  {
    path: 'customerCompliantList',
    element: <CustomerCompliantList />,
  },
  {
    path: 'customerCompliantCreate',
    element: <CustomerCompliantCreate />,
  },

  // tools and critical spare parts
  // machine and tools
  {
    path: 'masterlistofToolList',
    element: <MasterListTool />,
  },
  {
    path: 'obseleteMasterlistofToolList',
    element: <ObseleteToolList />,
  },
  {
    path: 'defect_list',
    element: <ListOfDefects />,
  },
  {
    path: 'customerComplaintRegister',
    element: <CustomerComplaintList />,
  },
  {
    path: 'supplierComplaintRegister',
    element: <SupplierComplaintList />,
  },
  {
    path: 'productionAndMaintenaceHome',
    element: <ProductionAndMaintenanceTiles />,
  },
  {
    path: 'masterListLocationList',
    element: <MasterListLocationList />,
  },
  {
    path: 'masterListApprovedExternalProvederList',
    element: <MasterListOfApprovedExtenalProviderList />,
  },
  {
    path: 'itemMasterVsMachineAndTool',
    element: <ItemMasterVsMachineAndTool />,
  },
  {
    path: 'dailyProductionTiles',
    element: <DailyProductionTile />,
  },
  //part stoke rate
  {
    path: 'viewItemMasterList',
    element: <ItemMasterList />,
  },
  {
    path: 'partStrokeRateList/:partId',
    element: <PartStrokeList />,
  },
  //
  // shift creation
  {
    path: 'shiftCreationTiles',
    element: <ShiftCreationTiles />,
  },
  {
    path: 'listOfScheduledDowntime',
    element: <ListOfScheduleDowntime />,
  },
  {
    path: 'shiftSchedulingList',
    element: <ShiftSchedulingList />,
  },
  {
    path: 'createShiftSchedulingDetails/:id',
    element: <CreateShiftSchedulingDetails />,
  },
  //start defect list
  {
    path: 'defectList',
    element: <DefectList />,
  },
  //end defect list
  //start dailyproduction revit
  {
    path: 'createEditRevitDailyProduction',
    element: <CreateRevitDailyProduction />,
  },
  // end dailyproduction revit
  // start dailyproduction rivet dashboard
  {
    path: 'createEditRevitDailyProductionPlanning',
    element: <CreateRevitDailyProductionPlanning />,
  },
  // end dailyproduction rivet dashboard
  {
    path: 'hireCandidatePortalList',
    element: <HireCandidatePortalList />,
  },
  {
    path: 'masterListDesignation',
    element: <MasterListDesignation />,
  },
  //dailyproduction revit

  //Mushin Help Desk
  {
    path: 'mushinHelpDesk',
    element: <MushinHelpDesk />,
  },
  //Mushin Aqua Master
  {
    path: 'masterListOfEmployeeType',
    element: <MasterListEmployeeTypeList />,
  },
  {
    path: 'masterListOfQualification',
    element: <MasterListQualificationList />,
  },
  {
    path: 'masterListOfSkillLevel',
    element: <MasterListSkillLevelList />,
  },
  ...clause5Eduction,
  ...forMSummary,
  ...clause3ForM,
  ...productions,
  ...clause8Modules,
  ...other,

  ...spc,
  ...MSA,
  ...ncReport,
];
