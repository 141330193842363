import moment from 'moment';
import lodash from 'lodash';

export const processes = [
  {
    date: moment(),
    shift: 'DAY',
    mc_no: null,
    part_id: null,
    rotor_size: null,
    batch_number: null,
    operator: null,
    operator_2: null,
    time_start: null,
    time_stop: null,
    shot: null,
    no_of_cavity: null,
    total_qty: null,
    ok_qty: null,
    rej_qty: null,
    verified_sign: null,
    remarks: null,
    rejection_details: Array.from({ length: 10 }, () =>
      lodash.cloneDeep({ reason: null, rej_qty: null }),
    ),
  },
];

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  dailyProduction_date: new Date(),
  processes: Array.from({ length: 15 }, () =>
    lodash.cloneDeep({ ...processes[0] }),
  ),
};
